import { createRouter, createWebHistory } from "vue-router";
const routes = [
	{
		path: "/general",
		component: () => import("@/views/Home/Home.vue"),
		meta: {
			layout: "main",
			title: "Главная",
			name: "",
		},
	},
	{
		path: "/",
		component: () => import("@/views/Auth/Auth.vue"),
		meta: {
			layout: "auth",
			title: "Вход",
			name: "",
		},
	},
	{
		path: "/clearArea",
		component: () => import("@/views/ClearArea/ClearArea.vue"),
		meta: {
			layout: "main",
			title: "Санитарная зона",
			name: "Санитарная зона",
		},
	},
	{
		path: "/businessCons",
		component: () =>
			import("@/views/businessConsultations/businessConsultations.vue"),
		meta: {
			layout: "main",
			title: "Консультации предпринимателей",
			name: "Консультации предпринимателей",
		},
	},
	{
		path: "/budgetExecution",
		component: () => import("@/views/budgetExecution/budgetExecution.vue"),
		meta: {
			layout: "main",
			title: "Исполнение бюджета",
			name: "Исполнение бюджета",
		},
		children: [
			{
				path: "/budgetExecution",
				component: () =>
					import("@/views/budgetExecution/datalens/datalens.vue"),
				meta: {
					layout: "main",
					title: "Сводная статистика",
					name: "Исполнение нац. проектов",
				},
			},
			{
				path: "/budgetExecution/jupyter",
				component: () =>
					import("@/views/budgetExecution/jupyter/jupyter.vue"),
				meta: {
					layout: "main",
					title: "Аналитика и прогноз",
					name: "Исполнение нац. проектов",
				},
			},
			{
				path: "/budgetExecution/newView",
				component: () =>
					import("@/views/budgetExecution/newView/newView.vue"),
				meta: {
					layout: "main",
					title: "Аналитика и прогноз",
					name: "Исполнение нац. проектов",
				},
			},
			{
				path: "/budgetExecution/lastYear",
				component: () =>
					import("@/views/budgetExecution/lastYear/lastYear.vue"),
				meta: {
					layout: "main",
					title: "Исполнение нац. проектов 2024",
					name: "Исполнение нац. проектов",
				},
			},
			{
				path: "/budgetExecution/map",
				component: () =>
					import("@/views/budgetExecution/map/map.vue"),
				meta: {
					layout: "main",
					title: "Карта нац. проектов",
					name: "Исполнение нац. проектов",
				},
			},
			{
				path: "/budgetExecution/mapLastYear",
				component: () =>
					import("@/views/budgetExecution/mapLastYear/mapLastYear.vue"),
				meta: {
					layout: "main",
					title: "Карта нац. проектов за 2024",
					name: "Исполнение нац. проектов",
				},
			},
		],
	},
	{
		path: "/covidDiseaseStatistics",
		component: () =>
			import("@/views/covidDiseaseStatistics/covidDiseaseStatistics.vue"),
		meta: {
			layout: "main",
			title: "Статистика заболеваний ковид-19 по области и городу",
			name: "Статистика заболеваний ковид-19 по области и городу",
		},
	},
	{
		path: "/cityOfficials",
		component: () => import("@/views/cityOfficials/cityOfficials.vue"),
		meta: {
			layout: "main",
			title: "Сотрудники администрации города",
			name: "Сотрудники администрации города",
		},
	},
	{
		path: "/whooshActivity",
		component: () => import("@/views/whooshActivity/whooshActivity.vue"),
		meta: {
			layout: "main",
			title: "Текущая активность самокатов",
			name: "Текущая активность самокатов",
		},
	},
	{
		path: "/sportsmens",
		component: () => import("@/views/sportsmens/sportsmens.vue"),
		meta: {
			layout: "main",
			title: "Спорт в каждый двор",
			name: 'Показатели проекта "Спорт в каждый двор"',
		},
	},
	{
		path: "/socialNetworks",
		component: () => import("@/views/socialNetworks/socialNetworks.vue"),
		meta: {
			layout: "main",
			title: "Социальные сети",
			name: "Социальные сети",
		},
		children: [
			{
				path: "/socialNetworks",
				component: () =>
					import(
						"@/views/socialNetworks/page/statistic/statistic.vue"
					),
				meta: {
					layout: "main",
					title: "Статистика",
					name: "Статистика",
				},
			},
			{
				path: "/socialNetworks/datalens",
				component: () =>
					import("@/views/socialNetworks/page/datalens/datalens.vue"),
				meta: {
					layout: "main",
					title: "Аналитика соц. сетей",
					name: "Статистика",
				},
			},
		],
	},
	{
		path: "/nto",
		component: () => import("@/views/HTO/HTO.vue"),
		meta: {
			layout: "main",
			title: "Нестационарные торговые объекты",
			name: "Нестационарные торговые объекты",
		},
	},
	{
		path: "/tourists",
		component: () => import("@/views/tourists/tourists.vue"),
		meta: {
			layout: "main",
			title: "Туризм в Нижнем Новгороде",
			name: "Туризм в Нижнем Новгороде",
		},
	},
	{
		path: "/reportWinter",
		component: () => import("@/views/ReportWinter/ReportWinter.vue"),
		meta: {
			layout: "main",
			title: "Сводная статистика по дорожной технике",
			name: "Сводная статистика по дорожной технике",
		},
	},
	{
		path: "/reportSummer",
		component: () => import("@/views/ReportSummer/ReportSummer.vue"),
		meta: {
			layout: "main",
			title: "Сводная статистика по дорожной технике",
			name: "Сводная статистика по дорожной технике",
		},
	},
	{
		path: "/activeSessions",
		component: () => import("@/views/activeSessions/activeSessions.vue"),
		meta: {
			layout: "main",
			title: "Активные сессии",
			name: "Активные сессии",
		},
	},
	{
		path: "/fines",
		component: () => import("@/views/Fines/Fines.vue"),
		meta: {
			layout: "main",
			title: "Административное делопроизводство",
			name: "Административное делопроизводство",
		},
	},
	{
		path: "/airQuality",
		component: () => import("@/views/airQuality/airQuality.vue"),
		meta: {
			layout: "main",
			title: "Качество воздуха",
			name: "Качество воздуха",
		},
	},
	{
		path: "/parkVisitors",
		component: () => import("@/views/parkVisitors/parkVisitors.vue"),
		children: [
			{
				path: "amcharts",
				component: () =>
					import("@/views/parkVisitors/amcharts/amcharts.vue"),
			},
			{
				path: "dataLens",
				component: () =>
					import("@/views/parkVisitors/dataLens/dataLens.vue"),
			},
		],
		meta: {
			layout: "main",
			title: "Количество посетителей",
			name: "Количество посетителей",
		},
	},
	{
		path: "/feedbackSystem",
		component: () => import("@/views/feedbackSystem/feedbackSystem.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/feedbackSystem",
				component: () =>
					import("@/views/feedbackSystem/page/datalens.vue"),
				meta: {
					layout: "main",
					title: "Статистика обратной связи",
					name: "Обратная связь по питанию в школах города",
				},
			},
			{
				path: "/feedbackSystem/cloud",
				component: () =>
					import("@/views/feedbackSystem/page/cloud.vue"),
				meta: {
					layout: "main",
					title: "Облако тегов",
					name: "Обратная связь по питанию в школах города",
				},
			},
		],
	},
	{
		path: "/technique",
		component: () => import("@/views/Technique/technique.vue"),
		meta: {
			layout: "main",
			title: "Online-контроль дорожной техники",
			name: "Карта активности дорожной техники",
		},
	},
	{
		path: "/technique/:id",
		component: () => import("@/views/Technique/organization.vue"),
		meta: {
			layout: "main",
			title: "Организация",
			name: "",
		},
	},
	{
		path: "/school",
		component: () => import("@/views/school/school.vue"),
		meta: {
			layout: "main",
			title: "Статистика по школам",
			name: "Статистика по школам",
		},
	},
	{
		path: "/categories",
		component: () => import("@/views/categories/categories.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/categories",
				component: () =>
					import("@/views/categories/page/analytics/analytics.vue"),
				meta: {
					layout: "main",
					title: "Аналитика",
					name: "Темы сообщений жителей",
				},
			},
			{
				path: "/categories/nlp",
				component: () => import("@/views/categories/page/nlp/nlp.vue"),
				meta: {
					layout: "main",
					title: "Поиск по ключевым словам",
					name: "Темы сообщений жителей",
				},
			},
			{
				path: "/categories/map",
				component: () => import("@/views/categories/page/map/map.vue"),
				meta: {
					layout: "main",
					title: "Карта",
					name: "Темы сообщений жителей",
				},
			},
			{
				path: "/categories/detailedAnalitics",
				component: () =>
					import(
						"@/views/categories/page/detailedAnalitics/detailedAnalitics.vue"
					),
				meta: {
					layout: "main",
					title: "Детальная статистика",
					name: "Темы сообщений жителей",
				},
			},
			{
				path: "/categories/tagsCloud",
				component: () =>
					import(
						"@/views/categories/page/tagsCloud/tagsCloud.vue"
					),
				meta: {
					layout: "main",
					title: "Облако тегов",
					name: "Облако тегов",
				},
			},
		],
	},
	{
		path: "/unemploymentStat",
		component: () =>
			import("@/views/unemploymentStat/unemploymentStat.vue"),
		meta: {
			layout: "main",
			title: "Доход и уровень безработицы населения города",
			name: "Доход и уровень безработицы населения города",
		},
	},
	// StatusesDistrictDepartments
	{
		path: "/statusesDistrictDepartments",
		component: () =>
			import(
				"@/views/statusesDistrictDepartments/statusesDistrictDepartments.vue"
			),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/statusesDistrictDepartments/district",
				component: () =>
					import(
						"@/views/statusesDistrictDepartments/page/district/district.vue"
					),
				meta: {
					layout: "main",
					title: "Статусы по районам",
					name: "Статусы по районам",
				},
			},
			{
				path: "/statusesDistrictDepartments/department",
				component: () =>
					import(
						"@/views/statusesDistrictDepartments/page/department/department.vue"
					),
				meta: {
					layout: "main",
					title: "Статусы по департаментам",
					name: "Статусы по департаментам",
				},
			},
		],
	},

	{
		path: "/sources",
		component: () => import("@/views/sources/sources.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/sources",
				component: () => import("@/views/sources/chart/chart.vue"),
				meta: {
					layout: "main",
					title: "Распределение заявок по дням и источникам",
					name: "Незакрытые по источникам",
				},
			},
			{
				path: "/sources/averagesTime",
				component: () =>
					import("@/views/sources/averagesTime/averagesTime.vue"),
				meta: {
					layout: "main",
					title: "Среднее время обработки",
					name: "Незакрытые по источникам",
				},
			},
		],
	},
	{
		path: "/quantityByDay/heatMap",
		component: () => import("@/views/quantityByDay/quantityByDay.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/quantityByDay/heatMap",
				component: () =>
					import("@/views/quantityByDay/heatMap/heatMap.vue"),
				meta: {
					layout: "main",
					title: "Количество по дням",
					name: "Количество по дням",
				},
			},
			{
				path: "/quantityByDay/distributionDist",
				component: () =>
					import(
						"@/views/quantityByDay/distributionDist/distributionDist.vue"
					),
				meta: {
					layout: "main",
					title: "Количество по дням",
					name: "Количество по дням",
				},
			},
			{
				path: "/quantityByDay/analitic",
				component: () =>
					import("@/views/categories/page/analytics/analytics.vue"),
				meta: {
					layout: "main",
					title: "Количество по дням",
					name: "Количество по дням",
				},
			},
		],
	},
	{
		path: "/demography",
		component: () => import("@/views/demographyPage/demographyPage.vue"),
		meta: {
			layout: "main",
			title: "Демографические показатели по городу",
			name: "Демографические показатели по городу",
		},
	},
	{
		path: "/parking",
		component: () => import("@/views/parking/parking.vue"),
		meta: {
			layout: "main",
			title: "Парковки",
			name: "",
		},
		children: [
			{
				path: "/parking",
				component: () => import("@/views/parking/charts/charts.vue"),
				meta: {
					layout: "main",
					title: " ",
					name: " ",
				},
			},
			{
				path: "/parking/tables",
				component: () => import("@/views/parking/tables/tables.vue"),
				meta: {
					layout: "main",
					title: "Карта парковочных зон",
					name: "Парковки",
				},
			},
		],
	},
	{
		path: "/builders",
		component: () => import("@/views/buildersPage/buildersPage.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/builders",
				component: () =>
					import(
						"@/views/buildersPage/page/summaryStatistics/summaryStatistics.vue"
					),
				meta: {
					layout: "main",
					title: "Сводная статистика",
					name: "Показатели с объектов КС города",
				},
			},
			{
				path: "/builders/detailedStatistics/:id",
				component: () =>
					import(
						"@/views/buildersPage/page/detailedStatistics/detailedStatistics.vue"
					),
				meta: {
					layout: "main",
					title: "Детальная статистика",
					name: "Показатели с объектов КС города",
				},
			},
			{
				path: "/builders/cameras/:id",
				component: () =>
					import("@/views/buildersPage/page/cameras/cameras.vue"),
				meta: {
					layout: "main",
					title: "Камеры",
					name: "Показатели с объектов КС города",
				},
			},
		],
	},
	{
		path: "/vkAPI",
		component: () => import("@/views/vkAPI/vkAPI.vue"),
		meta: {
			layout: "main",
			title: "Госпаблики",
			name: "Госпаблики",
		},
	},
	{
		path: "/JKH",
		component: () => import("@/views/JKH/JKH.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/JKH",
				component: () => import("@/views/JKH/page/datalens.vue"),
				meta: {
					layout: "main",
					title: "Жилищные объекты и управление",
					name: "Жилищные объекты и управление",
				},
			},
			{
				path: "/JKH/secondView",
				component: () => import("@/views/JKH/page/datalens2.vue"),
				meta: {
					layout: "main",
					title: "Жилищные объекты и управление",
					name: "Жилищные объекты и управление",
				},
			},
		],
	},
	{
		path: "/responseQuality",
		component: () => import("@/views/responseQuality/responseQuality.vue"),
		meta: {
			layout: "main",
			title: "Качество ответов исполнителей",
			name: "Качество ответов исполнителей",
		},
	},
	{
		path: "/SCUD_administration",
		component: () =>
			import("@/views/SCUDAdministration/SCUDAdministration.vue"),
		meta: {
			layout: "main",
			title: "Трудовая дисциплина",
			name: "Трудовая дисциплина",
		},
	},
	{
		path: "/platformEffeciency",
		component: () =>
			import("@/views/platformEffeciency/platformEffeciency.vue"),
		meta: {
			layout: "main",
			title: "Эффективность работы платформы",
			name: "Эффективность работы платформы",
		},
	},
	{
		path: "/indicatorsUDS",
		component: () => import("@/views/indicatorsUDS/indicatorsUDS.vue"),
		meta: {
			layout: "main",
			title: "Показатели предприятий УДС в зимний период",
			name: "Показатели предприятий УДС в зимний период",
		},
	},
	{
		path: "/sportmap",
		component: () => import("@/views/SportMap/sportmap.vue"),
		meta: {
			layout: "main",
			title: "Спортивные площадки города",
			name: "Спортивные площадки города",
		},
	},
	{
		path: "/problem_appeals",
		component: () =>
			import("@/views/problematicAppeals/problematicAppeals.vue"),
		meta: {
			layout: "main",
			title: "Особо проблемные обращения",
			name: "Особо проблемные обращения",
		},
		children: [
			{
				path: "/problem_appeals",
				component: () =>
					import(
						"@/views/problematicAppeals/page/statistic/statistic.vue"
					),
				meta: {
					layout: "main",
					title: "Все обращения",
					name: "Особо проблемные обращения",
				},
			},
			{
				path: "/problem_appeals/medialogy",
				component: () =>
					import(
						"@/views/problematicAppeals/page/datalens/datalens.vue"
					),
				meta: {
					layout: "main",
					title: "Медиалогия",
					name: "Особо проблемные обращения",
				},
			},
		],
	},
	{
		path: "/governmentPublic",
		component: () =>
			import("@/views/governmentPublic/governmentPublic.vue"),
		meta: {
			layout: "main",
			title: "Внешние СМИ",
			name: "Информационные сети",
		},
	},
	{
		path: "/policeman",
		component: () => import("@/views/policeman/policeman.vue"),
		meta: {
			layout: "main",
			title: "Мониторинг активности городовых",
			name: "Мониторинг активности городовых",
		},
	},
	{
		path: "/weather",
		component: () => import("@/views/weatherPage/weatherPage.vue"),
		meta: {
			layout: "main",
			title: "Погода",
			name: "Погода",
		},
		children: [
			{
				path: "/weather",
				component: () =>
					import("@/views/weatherPage/dataLens/dataLens.vue"),
				meta: {
					layout: "main",
					title: "Погода",
					name: "Погода",
				},
			},
			{
				path: "/weather/jupyter",
				component: () =>
					import("@/views/weatherPage/jupyter/jupyter.vue"),
				meta: {
					layout: "main",
					title: "Прогнозирование",
					name: "Погода",
				},
			},
		],
	},
	{
		path: "/firedAppeals",
		component: () => import("@/views/firedAppeals/firedAppeals.vue"),
		meta: {
			layout: "main",
			title: "",
			name: "",
		},
		children: [
			{
				path: "/firedAppeals",
				component: () =>
					import("@/views/firedAppeals/chart/firedAppeals.vue"),
				meta: {
					layout: "main",
					title: "Детализация",
					name: "Просроченные",
				},
			},
			{
				path: "/firedAppeals/total",
				component: () => import("@/views/firedAppeals/total/total.vue"),
				meta: {
					layout: "main",
					title: "Сводная",
					name: "Просроченные",
				},
			},
		],
	},
	{
		path: "/illegalFinancialCompanies",
		component: () =>
			import(
				"@/views/illegalFinancialCompanies/illegalFinancialCompanies.vue"
			),
		meta: {
			layout: "main",
			title: "Нелегальные организации города",
			name: "Нелегальные финансовые организации города",
		},
	},
	{
		path: "/closedApplications",
		component: () =>
			import("@/views/closedApplications/closedApplications.vue"),
		meta: {
			layout: "main",
			title: "Закрытые обращения",
			name: "Закрытые обращения",
		},
	},
	{
		path: "/sportProfile",
		component: () => import("@/views/sportProfile/sportProfile.vue"),
		meta: {
			layout: "main",
			title: "Профиль спортивного жителя",
			name: "Профиль спортивного жителя",
		},
	},
	{
		path: "/billboards",
		component: () => import("@/views/billboardsPage/billboardsPage.vue"),
		meta: {
			layout: "main",
			title: "Рекламные конструкции",
			name: "Рекламные конструкции",
		},
	},
	{
		path: "/staff",
		component: () => import("@/views/staff/staff.vue"),
		meta: {
			layout: "main",
			title: "Кадровые изменения",
			name: "Кадровые изменения",
		},
	},
	{
		path: "/orders",
		component: () => import("@/views/orders/orders.vue"),
		meta: {
			layout: "main",
			title: "Ордера",
			name: "Ордера",
		},
		children: [
			{
				path: "/orders",
				component: () =>
					import(
						"@/views/orders/warrantsByDistrict/warrantsByDistrict.vue"
					),
				meta: {
					layout: "main",
					title: "Земельные ордера",
					name: "Ордера",
				},
			},
			{
				path: "/orders/statistics",
				component: () =>
					import("@/views/orders/dataLence/dataLence.vue"),
				meta: {
					layout: "main",
					title: "Сводная статистика",
					name: "Ордера",
				},
			},
			{
				path: "/orders/map",
				component: () =>
					import("@/views/orders/mapOrdersNew/mapOrdersNew.vue"),
				meta: {
					layout: "main",
					title: "Карта",
					name: "Ордера",
				},
			},
		],
	},
	{
		path: "/newAirQuality",
		component: () => import("@/views/newAirQuality/newAirQuality.vue"),
		meta: {
			layout: "main",
			title: "Качество воздуха",
			name: "Качество воздуха",
		},
		children: [
			{
				path: "/newAirQuality",
				component: () =>
					import(
						"@/views/newAirQuality/page/statistics/statistics.vue"
					),
				meta: {
					layout: "main",
					title: "Качество воздуха",
					name: "Качество воздуха",
				},
			},
			{
				path: "/newAirQuality/analytics",
				component: () =>
					import("@/views/newAirQuality/page/datalens/datalens.vue"),
				meta: {
					layout: "main",
					title: "Качество воздуха",
					name: "Качество воздуха",
				},
			},
		],
	},
	{
		path: "/prodCart",
		component: () => import("@/views/prodCart/prodCart.vue"),
		meta: {
			layout: "main",
			title: "Продуктовая корзина",
			name: "Продовольственная корзина",
		},
	},
	{
		path: "/initiativeWidget",
		component: () =>
			import("@/views/initiativeWidget/initiativeWidget.vue"),
		meta: {
			layout: "main",
			title: "Инициативы",
			name: "Инициативы",
		},
	},
	{
		path: "/eventPoster",
		component: () => import("@/views/eventPoster/eventPoster.vue"),
		meta: {
			layout: "main",
			title: "Афиша мероприятий",
			name: "Афиша мероприятий",
		},
	},
	{
		path: "/snowRemovalByPerformers",
		component: () =>
			import(
				"@/views/snowRemovalByPerformers/snowRemovalByPerformers.vue"
			),
		meta: {
			layout: "main",
			title: "Что то снег",
			name: "Что то снег",
		},
		children: [
			{
				path: "/snowRemovalByPerformers",
				component: () =>
					import(
						"@/views/snowRemovalByPerformers/components/map/map.vue"
					),
				meta: {
					layout: "main",
					title: "Уборка снега исполнителями",
					name: "Уборка снега исполнителями",
				},
			},
			{
				path: "/snowRemovalByPerformers/statistic",
				component: () =>
					import(
						"@/views/snowRemovalByPerformers/components/statistic/statistic.vue"
					),
				meta: {
					layout: "main",
					title: "Уборка снега исполнителями",
					name: "Уборка снега исполнителями",
				},
			},
			{
				path: "/snowRemovalByPerformers/map",
				component: () =>
					import(
						"@/views/snowRemovalByPerformers/components/tagCloud/tagCloud.vue"
					),
				meta: {
					layout: "main",
					title: "Площадки ТКО",
					name: "Площадки ТКО",
				},
			},
			{
				path: "/snowRemovalByPerformers/snowMeltingStations",
				component: () =>
					import(
						"@/views/snowRemovalByPerformers/components/snowMeltingStations/snowMeltingStations.vue"
					),
				meta: {
					layout: "main",
					title: "Уборка снега исполнителями",
					name: "Уборка снега исполнителями",
				},
			},
			{
				path: "/snowRemovalByPerformers/TKOTransportRoutes",
				component: () =>
					import(
						"@/views/snowRemovalByPerformers/components/TKOTransportRoutes/TKOTransportRoutes.vue"
					),
				meta: {
					layout: "main",
					title: "Маршруты техники ТКО",
					name: "Маршруты техники ТКО",
				},
			},
		],
	},
	{
		path: "/anomalousSchools",
		component: () =>
			import("@/views/anomalousSchools/anomalousSchools.vue"),
		meta: {
			layout: "main",
			title: "Аномальные показатели по питанию",
			name: "Аномальные показатели по питанию",
		},
		children: [
			{
				path: "/anomalousSchools",
				component: () =>
					import(
						"@/views/anomalousSchools/pages/nutritionIndicators.vue"
					),
				meta: {
					layout: "main",
					title: "Показатели по питанию",
					name: "Показатели по питанию",
				},
			},

      {
        path: "/anomalousSchools/map",
        component: () => import("@/views/anomalousSchools/pages/map.vue"),
        meta: {
          layout: "main",
          title: "Логистическая модель",
          name: "Логистическая модель",
        },
      },
    ],
  },
  {
    path: "/incomeStatement",
    component: () => import("@/views/incomeStatement/incomeStatement.vue"),
    meta: {
      layout: "main",
      title: "Справки о доходах",
      name: "Справки о доходах",
    },
  },
  {
    path: "/otherIncome",
    component: () => import("@/views/otherIncome/otherIncome.vue"),
    meta: {
      layout: "main",
      title: "Иные доходы",
      name: "Иные доходы",
    },
  },
  {
    path: "/governmentPurchase",
    component: () =>
      import("@/views/governmentPurchase/governmentPurchase.vue"),
    meta: {
      layout: "main",
      title: "",
      name: "",
    },
    children: [
      {
        path: "/governmentPurchase",
        component: () =>
          import("@/views/governmentPurchase/analytics/analytics.vue"),
        meta: {
          layout: "main",
          title: "Аналитика",
          name: "Муниципальные закупки",
        },
      },
      {
        path: "/governmentPurchase/purches",
        component: () =>
          import("@/views/governmentPurchase/purches/purches.vue"),
        meta: {
          layout: "main",
          title: "Витрина закупок",
          name: "Муниципальные закупки",
        },
      },
    ],
  },
  {
    path: "/interesiesConflict",
    component: () =>
      import("@/views/interesiesConflict/interesiesConflict.vue"),
    meta: {
      layout: "main",
      title: "Конфликт интересов",
      name: "Конфликт интересов",
    },
  },
  {
    path: "/sportTypes",
    component: () => import("@/views/sportTypes/sportTypes.vue"),
    meta: {
      layout: "main",
      title: "Ветераны спорта",
      name: "Ветераны спорта Нижнего Новгорода",
    },
  },
  {
    path: "/markKRU",
    component: () => import("@/views/markKRU/markKRU.vue"),
    meta: {
      layout: "main",
      title: "Основные показатели КРУ",
      name: "Показатели КРУ",
    },
  },
  {
    path: "/rewarding",
    component: () => import("@/views/rewarding/rewarding.vue"),
    meta: {
      layout: "main",
      title: "Награждения",
      name: "Награждения",
    },
  },
  {
    path: "/decide",
    component: () => import("@/views/youDecide/youDecide.vue"),
    meta: {
      layout: "main",
      title: "Вам решать",
      name: "Вам решать",
    },
  },
  {
    path: "/constructor",
    component: () => import("@/views/constructor/constructor.vue"),
    meta: {
      layout: "main",
      title: "Конструктор форм",
      name: "Конструктор форм",
    },
  },
  {
    path: "/summerUds",
    component: () => import("@/views/summerUds/summerUds.vue"),
    meta: {
      layout: "main",
      title: "Показатели предприятий УДС в летний период",
      name: "Показатели предприятий УДС в летний период",
    },
  },
  {
    path: "/municipalStaffPortrait",
    component: () =>
      import("@/views/municipalStaffPortrait/municipalStaffPortrait.vue"),
    meta: {
      layout: "main",
      title: "Портрет муниципального служащего",
      name: "Портрет муниципального служащего",
    },
  },
  {
    path: "/administrationInterships",
    component: () =>
      import("@/views/administrationInterships/administrationInterships.vue"),
    meta: {
      layout: "main",
      title: "Практическая подготовка в Администрации города",
      name: "Практическая подготовка в Администрации города",
    },
  },
  {
    path: "/buildersWidget2",
    component: () => import("@/views/buildersPage2/buildersPage2.vue"),
    meta: {
      layout: "main",
      title: "Финансовые показатели объектов КС города",
      name: "Финансовые показатели объектов КС города",
    },
  },
  // {
  //   path: '/porucheniyaGlavy',
  //   component: () => import('@/views/porucheniyaGlavy/porucheniyaGlavy.vue'),
  //   meta: {
  //     layout: 'main',
  //     title: 'Поручения Главы',
  //     name: 'Поручения Главы',
  //   },
  // },
  {
    path: "/lobachevskyUsers",
    component: () => import("@/views/lobachevskyUsers/lobachevskyUsers.vue"),
    meta: {
      layout: "main",
      title: "Пользователи Лобачевского",
      name: "Активность обращения жителей",
    },
  },
  {
    path: "/mayorsOrders",
    component: () => import("@/views/mayorsOrders/mayorsOrders.vue"),
    meta: {
      layout: "main",
      title: " ",
      name: " ",
    },
    children: [
      {
        path: "/mayorsOrders",
        component: () => import("@/views/mayorsOrders/chart/mayorsOrders.vue"),
        meta: {
          layout: "main",
          title: "Поручения с обходов",
          name: "Поручения Главы",
        },
      },
      {
        path: "/mayorsOrders/broadCast",
        component: () =>
          import(
            "@/views/mayorsOrders/mayorsOrdersBroadcast/mayorsOrdersBroadcast.vue"
          ),
        meta: {
          layout: "main",
          title: "Поручения с эфиров",
          name: "Поручения Главы",
        },
      },
      {
        path: "/mayorsOrders/necessaryFinantial",
        component: () =>
          import(
            "@/views/mayorsOrders/mayorsOrdersNecessaryFinantial/mayorsOrdersNecessaryFinantial.vue"
          ),
        meta: {
          layout: "main",
          title: "Необходимое финансирование",
          name: "Поручения Главы",
        },
      },
      {
        path: "/mayorsOrders/inspect",
        component: () =>
          import(
            "@/views/mayorsOrders/mayorsOrdersInspect/mayorsOrdersInspect.vue"
          ),
        meta: {
          layout: "main",
          title: "Поручения с инспекций",
          name: "Поручения Главы",
        },
      },
      {
        path: "/mayorsOrders/fired",
        component: () =>
          import(
            "@/views/mayorsOrders/firedAppeals/firedAppeals.vue"
          ),
        meta: {
          layout: "main",
          title: "Просроченные",
          name: "Поручения Главы",
        },
      },
    ],
  },
  {
    path: "/laborMarketStats",
    component: () => import("@/views/laborMarketStats/laborMarketStats.vue"),
    meta: {
      layout: "main",
      title: "Статистика рынка труда Нижнего Новгорода",
      name: "Статистика рынка труда Нижнего Новгорода",
    },
  },
  {
    path: "/kindergardenFeedback",
    component: () =>
      import("@/views/kindergardenFeedback/kindergardenFeedback.vue"),
    meta: {
      layout: "main",
      title: "Обратная связь в детских садах",
      name: "Обратная связь в детских садах",
    },
  },
  {
    path: "/personnelTraining",
    component: () => import("@/views/personnelTraining/personnelTraining.vue"),
    meta: {
      layout: "main",
      title: "Обучение сотрудников",
      name: "Обучение сотрудников",
    },
  },
  {
    path: "/KUGIWidget",
    component: () => import("@/views/KUGIWidget/KUGIWidget.vue"),
    meta: {
      layout: "main",
      title: "Реестр имущества",
      name: "Реестр имущества",
    },
  },
  {
    path: "/touchModerators",
    component: () => import("@/views/touchModerators/touchModerators.vue"),
    meta: {
      layout: "main",
      title: "Взаимодействие модераторов с платформой",
      name: "Взаимодействие модераторов с платформой",
    },
  },
  {
    path: "/pushkinMap",
    component: () => import("@/views/pushkinMap/pushkinMap.vue"),
    meta: {
      layout: "main",
      title: "Пушкинская карта",
      name: "Пушкинская карта",
    },
  },
  {
    path: "/sportsGroundsFeedback",
    component: () =>
      import("@/views/sportsGroundsFeedback/sportsGroundsFeedback.vue"),
    meta: {
      layout: "main",
      title: "Обратная связь по спортивным площадкам",
      name: "Обратная связь по спортивным площадкам",
    },
  },
  {
    path: "/cityLifeQualityIndex",
    component: () =>
      import("@/views/cityLifeQualityIndex/cityLifeQualityIndex.vue"),
    meta: {
      layout: "main",
      title: "Индекс качества жизни города",
      name: "Индекс качества жизни города",
    },
  },
  {
    path: "/involvement",
    component: () => import("@/views/involvement/involvement.vue"),
    meta: {
      layout: "main",
      title: "Вовлеченность",
      name: "Вовлеченность",
    },
  },
  {
    path: "/trainingAttendance",
    component: () =>
      import("@/views/trainingAttendance/trainingAttendance.vue"),
    meta: {
      layout: "main",
      title: "Системное управление",
      name: "Системное управление на муниципальной службе",
    },
  },
  {
    path: "/socialPrecinct",
    component: () => import("@/views/socialPrecinct/parentPage.vue"),
    meta: {
      layout: "main",
      title: "Социальный участковый",
      name: "Социальный участковый",
    },
    children: [
      {
        path: "/socialPrecinct",
        component: () =>
          import("@/views/socialPrecinct/appeals/socialPrecinct.vue"),
        layout: "main",
        title: "Социальный участковый",
        name: "Социальный участковый",
      },
      {
        path: "/socialPrecinct/loyalty",
        component: () => import("@/views/socialPrecinct/loyalty/loyalty.vue"),
        layout: "main",
        title: "Лояльность",
        name: "Лояльность",
      },
      {
        path: "/socialPrecinct/governor",
        component: () => import("@/views/socialPrecinct/governor/governor.vue"),
        layout: "main",
        title: "Прямая линия с Губернатором",
        name: "Прямая линия с Губернатором",
      },
    ],
  },
  {
    path: "/messageForwarding",
    component: () => import("@/views/messageForwarding/messageForwarding.vue"),
    meta: {
      layout: "main",
      title: "Особо проблемные обращения",
      name: "Особо проблемные обращения",
    },
    children: [
      {
        path: "/messageForwarding/analiticDatalence",
        component: () =>
          import(
            "@/views/messageForwarding/analiticDatalence/analiticDatalence.vue"
          ),
        meta: {
          layout: "main",
          title: "Все обращения",
          name: "Пересылка сообщений по исполнителям",
        },
      },
      {
        path: "/messageForwarding/slider",
        component: () => import("@/views/messageForwarding/slider/slider.vue"),
        meta: {
          layout: "main",
          title: "Детализация",
          name: "Пересылка сообщений по исполнителям",
        },
      },
    ],
  },
  {
    path: "/qualityPerformersWork",
    component: () =>
      import("@/views/qualityPerformersWork/qualityPerformersWork.vue"),
    meta: {
      layout: "main",
      title: "Качество работы исполнителей",
      name: "Качество работы исполнителей",
    },
    children: [
      {
        path: "/qualityPerformersWork/analitic",
        component: () =>
          import(
            "@/views/qualityPerformersWork/pages/qualityPerformersWorkAnalitic.vue"
          ),
        meta: {
          layout: "main",
          title: "Качество работы исполнителей",
          name: "Качество работы исполнителей",
        },
      },
      {
        path: "/qualityPerformersWork/report",
        component: () =>
          import(
            "@/views/qualityPerformersWork/pages/qualityPerformersWorkReport.vue"
          ),
        meta: {
          layout: "main",
          title: "Качество работы исполнителей",
          name: "Качество работы исполнителей",
        },
      },
    ],
  },
  {
    path: "/cityDuma",
    component: () => import("@/views/cityDuma/cityDuma.vue"),
    meta: {
      layout: "main",
      title: "Взаимодействие с Городской Думой",
      name: "Взаимодействие с Городской Думой",
    },
  },
  {
    path: "/parksFeedback",
    component: () => import("@/views/parksFeedback/parksFeedback.vue"),
    meta: {
      layout: "main",
      title: "Обратная связь по парковым пространствам",
      name: "Обратная связь по парковым пространствам",
    },
  },
  {
    path: "/personnelChecks",
    component: () => import("@/views/personnelСhecks2/personnelСhecks2.vue"),
    meta: {
      layout: "main",
      title: "Кадровые проверки",
      name: "Кадровые проверки",
    },
  },
  {
    path: "/UDSBot",
    component: () => import("@/views/UDSBot/UDSBot.vue"),
    meta: {
      layout: "main",
      title: "Контроль уборки УДС",
      name: "Контроль уборки УДС",
    },
  },
  {
    path: "/appealsUnderFederalLaw59",
    component: () =>
      import("@/views/appealsUnderFederalLaw59/appealsUnderFederalLaw59.vue"),
    meta: {
      layout: "main",
      title: "Обращения по 59-ФЗ",
      name: "Обращения по 59-ФЗ",
    },
  },
  {
    path: "/citiesensFeedback",
    component: () => import("@/views/citiesensFeedback/citiesensFeedback.vue"),
    meta: {
      layout: "main",
      title: "Обратная связь по обращениям",
      name: "Обратная связь по обращениям",
    },
  },
  {
    path: "/analiticVK",
    component: () => import("@/views/analiticVK/analiticVK.vue"),
    meta: {
      layout: "main",
      title: "Аналитика посещения приложения ВК",
      name: "Аналитика посещения приложения ВК",
    },
  },
  {
    path: "/mediaReaction",
    component: () => import("@/views/mediaReaction/mediaReaction.vue"),
    meta: {
      layout: "main",
      title: "Показатели системы медиареагирования",
      name: "Показатели системы медиареагирования",
    },
  },
  {
    path: "/moiNNFeedback",
    component: () => import("@/views/moiNNFeedback/moiNNFeedback.vue"),
    meta: {
      layout: "main",
      title: "Обратная связь по обращениям из приложения Мой НН",
      name: "Обратная связь по обращениям из приложения Мой НН",
    },
  },
  {
    path: "/appealsVK",
    component: () => import("@/views/appealsVK/appealsVK.vue"),
    meta: {
      layout: "main",
      title: "Обращения из приложения Мой НН",
      name: "Обращения из приложения Мой НН",
    },
  },
  {
    path: "/appealsTG",
    component: () => import("@/views/appealsTG/appealsTG.vue"),
    meta: {
      layout: "main",
      title: "Обращения из телеграм-бота «Приёмная Главы города»",
      name: "Обращения из телеграм-бота «Приёмная Главы города»",
    },
  },
  {
    path: "/heatAndVodokanalShutdowns",
    component: () => import("@/views/heatAndVodokanalShutdowns/heatAndVodokanalShutdowns.vue"),
    meta: {
      layout: "main",
      title: "Отключения Теплоэнерго и Водоканала",
      name: "Отключения Теплоэнерго и Водоканала",
    },
  },  
  {
    path: "/controlAppeals",
    component: () => import("@/views/controlAppeals/controlAppeals.vue"),
    meta: {
      layout: "main",
      title: "Заявки на контроле",
      name: "Заявки на контроле",
    },
  },
  {
    path: "/consumerMarket",
    component: () => import("@/views/consumerMarket/consumerMarket.vue"),
    meta: {
      layout: "main",
      title: "Потребительский рынок",
      name: "Потребительский рынок",
    },
  },
  {
    path: "/EnterprisesActivities",
    component: () => import("@/views/EnterprisesActivities/EnterprisesActivities.vue"),
    meta: {
      layout: "main",
      title: "Деятельность предприятий",
      name: "Деятельность предприятий",
    },
  },
  {
    path: "/ComfortableEnvironment",
    component: () => import("@/views/ComfortableEnvironment/ComfortableEnvironment.vue"),
    meta: {
      layout: "main",
      title: "Индекс комфортной среды",
      name: "Индекс комфортной среды",
    },
  },
  {
    path: "/financingRoadNetwork",
    component: () => import("@/views/financingRoadNetwork/financingRoadNetwork.vue"),
    meta: {
      layout: "main",
      title: "Финансирование дорожной сети",
      name: "Финансирование дорожной сети",
    },
  },
  {
    path: "/utilisationSubsidiesTransfers",
    component: () => import("@/views/utilisationSubsidiesTransfers/utilisationSubsidiesTransfers.vue"),
    meta: {
      layout: "main",
      title: "Использование субсидий и трансфертов",
      name: "Использование субсидий и трансфертов",
    },
  },
  {
    path: "/reserveFundBalance",
    component: () => import("@/views/reserveFundBalance/reserveFundBalance.vue"),
    meta: {
      layout: "main",
      title: "Остаток резервного фонда",
      name: "Остаток резервного фонда",
    },
  },
  {
    path: "/budgetRevenues",
    component: () => import("@/views/budgetRevenues/budgetRevenues.vue"),
    meta: {
      layout: "main",
      title: "Доходы бюджета",
      name: "Доходы бюджета",
    },
  },
  {
    path: "/bunkers",
    component: () => import("@/views/bunkers/bunkers.vue"),
    meta: {
      layout: "main",
      title: "Пункты временного размещения",
      name: "Пункты временного размещения",
    },
  },
  {
    path: "/StreetPerformers",
    component: () => import("@/views/StreetPerformers/StreetPerformers.vue"),
    meta: {
      layout: "main",
      title: "Статистика системы уличных выступлений",
      name: "Статистика системы уличных выступлений",
    },
  },
  {
    path: "/publicSpaces",
    component: () => import("@/views/publicSpaces/publicSpaces.vue"),
    meta: {
      layout: "main",
      title: "Система бронирования общественных пространств",
      name: "Система бронирования общественных пространств",
    },
  },
  {
    path: "/economicEfficiencyACG",
    component: () => import("@/views/economicEfficiencyACG/economicEfficiencyACG.vue"),
    meta: {
      layout: "main",
      title: "Оценка эффективности АЦГ",
      name: "Оценка эффективности АЦГ",
    },
  },
  {
    path: "/snowTGBot",
    component: () => import("@/views/snowTGBot/index.vue"),
    meta: {
      layout: "main",
      title: "Контроль уборки дворов",
      name: "Контроль уборки дворов",
    },
	children: [
		{
			path: "/snowTGBot",
			component: () => import("@/views/snowTGBot/snowTGBot.vue"),
			meta: {
			  layout: "main",
			  title: "Контроль уборки дворов",
			  name: "Контроль уборки дворов",
			},
		},
		{
			path: "/snowTGBot/statistic",
			component: () => import("@/views/snowTGBot/statistic.vue"),
			meta: {
			  layout: "main",
			  title: "Статистика контроля уборки дворов",
			  name: "Статистика контроля уборки дворов",
			},
		},
	],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/general",
  },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

function ckeckAuthorizatioin(to, from) {
	if (to.path !== "/") {
		let localCookiesValue = document.cookie
			.split("; ")
			.reduce((prev, current) => {
				const [name, ...value] = current.split("=");
				prev[name] = value.join("=");
				return prev;
			}, {});

		if (localCookiesValue.clientID) {
			document.cookie = `clientID=; expires=${new Date(
				0,
				0,
				0
			).toUTCString()}`;
		}

		if (!localCookiesValue.newClientID) {
			router.replace("/");
		}
	}
}

router.beforeEach(ckeckAuthorizatioin);

export default router;
