import Cookies from "js-cookie";

export default {
    data() {
        return {
            srcValue: 1,
            length: Cookies.get("newClientID") !== "83" ? 27 : 24,
            isGlava: Cookies.get("newClientID") === "83",

            interval: undefined,
        }
    },
    methods: {
        forward() {
            if (this.length != this.srcValue) {
                this.srcValue++
            } else {
                this.srcValue = 1
            }
        },

        back() {
            if (this.srcValue != 1) {
                this.srcValue--;
            } else {
                this.srcValue = this.length;
            }
        },

        handleEsc() {
            this.$emit("closeWindow");
        },
    },

    mounted() {
        try {
            this.interval = setInterval(() => {
                if (this.$refs.someStuff !== null) {
                    this.$refs.someStuff.focus();
                }
            }, 500);
        } catch (error) {

        }
    }
}
